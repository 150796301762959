export default class Pagination {
  constructor(target, options) {
    this.container = target;
    this.defaultOptions = {
      pagesCount: 50,
      activeItem: 1,
      indexOffset: -2,
      delta: 3,
      onPaginationChange: () => {}
    };
    this.options = Object.assign(this.defaultOptions, options);
    this.options.activeItem -= this.options.indexOffset;
    this.paginationArray = [];
    this.disabled = false;
  }

  init() {
    if (!this.container) {
      return;
    }

    this.generatePaginationArray();
  }

  generatePaginationArray() {
    const current = this.options.activeItem;
    const last = this.options.pagesCount;
    const delta = this.options.delta;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    let prev = null;

    for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    for (const curr of range) {
      if (prev && curr - prev > 1 && curr !== last) {
        rangeWithDots.push('leftEllipsis');
      }

      if (prev && curr - prev > 1 && curr === last) {
        rangeWithDots.push('rightEllipsis');
      }

      rangeWithDots.push(curr);
      prev = curr;
    }

    this.paginationArray = rangeWithDots;
    this.generatePagination();
  }

  getItemTemplate(el) {
    const type = !isNaN(el) ? 'num' : el;

    return this[type](el);
  }

  leftEllipsis() {
    return '<li class="item ellipsis-left">...</li>';
  }

  rightEllipsis() {
    return '<li class="item ellipsis-right">z</li>';
  }

  prev() {
    return `
      <li class="item prev">
        <button type="button" data-no="prev">
          <span class="visually-hidden">Poprzednia strona</span>
          <span aria-hidden="true"><</span>
        </button>
      </li>`;
  }

  next() {
    return `
      <li class="item next">
        <button type="button" data-no="next">
          <span class="visually-hidden">Następna strona</span>
          <span aria-hidden="true">></span>
        </button>
      </li>`;
  }

  num(el) {
    const isActive = el === this.options.activeItem;
    const activeClass = isActive ? 'active' : '';
    const ariaCurrent = isActive ? 'aria-current="page"' : '';
    const tag = isActive ? 'span' : 'button';
    const type = isActive ? '' : 'type="button"';
    const classes = isActive ? 'button-wrapper' : '';

    return `
      <li class="item ${activeClass}">
        <${tag} ${type} class="${classes}" data-no="${el}" ${ariaCurrent}>
          <span class="visually-hidden">strona </span>
          ${el}
        </${tag}>
      </li>`;
  }

  generatePagination() {
    this.container.innerHTML = '';
    const html = [];

    this.paginationArray.forEach((el) => {
      const tpl = this.getItemTemplate(el);

      html.push(tpl);
    });

    this.container.innerHTML = html.join('');
    this.bindPagination();
  }

  bindPagination() {
    this.container.querySelectorAll('button').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const dataNum = e.currentTarget.dataset.no;
        const isBtnPrev = dataNum === 'prev';
        const isBtnNext = dataNum === 'next';
        const lastId = this.paginationArray[this.paginationArray.length - 2];
        const isCurrFirst = this.options.activeItem === 1;
        const isCurrLast = this.options.activeItem === lastId;

        if (!isNaN(dataNum)) {
          this.options.activeItem = parseInt(dataNum, 10);
        }

        if (isNaN(dataNum) && isBtnPrev && !isCurrFirst) {
          this.options.activeItem -= 1;
        }

        if (isNaN(dataNum) && isBtnNext && !isCurrLast) {
          this.options.activeItem += 1;
        }

        this.container.classList.add('disabled');
        this.setActiveItem();
      });
    });
  }

  setActiveItem() {
    const event = new CustomEvent('pagination.change', {
      detail: this.options.activeItem + this.options.indexOffset,
    });

    this.container.dispatchEvent(event);
    this.options.onPaginationChange(
      this.options.activeItem + this.options.indexOffset
    );
    this.generatePaginationArray();
  }

  update(options) {
    Object.assign(this.options, options);
    this.options.activeItem -= this.options.indexOffset;

    const event = new CustomEvent('pagination.change', {
      detail: this.options.activeItem + this.options.indexOffset,
    });

    this.container.dispatchEvent(event);
    this.generatePaginationArray();
    this.container.classList.remove('disabled');
  }

  get disabled() {
    return this._disabled;
  }

  set disabled(value) {
    this._disabled = value;
    this.container.classList.toggle('disabled', this._disabled);
  }
}