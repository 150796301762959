import axios from 'axios';
import Pagination from './pagination';

export default class SearchResults {
  constructor (target) {
    this.section = target;
    this.form = this.section.querySelector('.search-form');
    this.resultsContainer = this.section.querySelector('[search-result-wrap]');
    this.paginationContainer = this.section.querySelector('[search-result-pagination]');

    this.response = null;
    this.semanticResult = null;
    this.searchResult = null;
    this.categoryResult = null;

    this.pagination = null;
    this.tabs = null;

    this.text = {
        noItems: 'Brak wyników dla danej frazy',
        results: 'wyników'
    }

    this.currentQuery = '';
    this.pageSize = 10;
    this.page = 1;
    this.sortDirection = 'PUBLICATION_DATE_ASC';

    this.switchToDev = 0;
    this.contextPath = this.switchToDev ? 'https://ferrero-pub.dev.artegence.com' : CMS.contextPath;
    this.searchEndpoint = `${this.contextPath}/.rest/api/search/livesearch`;

    this.loadingDelay = 200;
  }

  async init () {
    this.loading(true);
    this.getCurrentQueryFromUrl();
    this.bindListeners();
    await this.getData();
    this.renderResults();
    await this.updatePagination();
  }


    updatePagination() {
    const totalPagesCount = this.searchResult ? Math.ceil(this.searchResult.total / this.pageSize) : 0;
    const hasPages = Boolean(totalPagesCount);
    const isInitialized = Boolean(this.pagination);
    const options = {
      pagesCount: totalPagesCount,

      delta: 1,
      indexOffset: 0,
      activeItem: this.page,
      onPaginationChange: async (activeItem) => {
        this.page = activeItem;
        this.loading();
        await this.getData();
        this.renderResults();
        await this.updatePagination();
      },
    };

    if (hasPages && !isInitialized) {
      this.pagination = new Pagination(this.paginationContainer, options);
      this.pagination.init();
    }

    if (hasPages && isInitialized) {
      this.pagination.update({
        activeItem: this.page,
        pagesCount: totalPagesCount,
      });
    }

    if (!hasPages) {
      this.paginationContainer.innerHTML = '';
      this.pagination = null;
    }
  }

  getCurrentQueryFromUrl () {
    const isPreview = this.section.classList.contains('preview-mode');
    const query = isPreview && new URL(window.location).searchParams.get('search');
    this.currentQuery = this.decodeQuery(query);

    this.form.querySelector('.search-bar-field').value = this.currentQuery;
  }

  decodeQuery (query) {
    let result = '';
    result = query && query.replace(/\+/g, '%20');
    result = result ? decodeURIComponent(result) : '';

    return result;
  }

  prepareFilters () {
    const queryFilters = {
      query: this.currentQuery,
      page: this.page,
      pageSize: this.pageSize,
      sort: this.sortDirection
    };

    return queryFilters;
  }

  async getData () {
    if (!this.currentQuery) {
      return;
    }
    let response = null;
    let responseCategory = null;

    const data = this.prepareFilters();
    const query = Object.keys(data).map(key =>  key.concat('=', data[key])).join('&');
    const url = this.searchEndpoint.concat('?', query);
    const urlCategory = `${this.contextPath}/.rest/search/categories`;
    try {
      response = await axios(url);
      this.response = response.data;
      responseCategory = await axios(urlCategory);
      this.responseCategory = responseCategory.data;
    } catch (err) {
      this.response = null;
      this.searchResult = null;
      this.semanticResult = null;
      this.responseCategory = null;
    }

    if (this.response) {
      this.semanticResult = this.response.phraseSearchResult;
      this.searchResult = this.response.pageSearchResult;
    }
    if (this.responseCategory) {
      this.categoryResult = this.responseCategory;
    }
    return 'dataend';
  }

  loading (isLoading = true) {
    if (!isLoading) {
      this.resultsContainer.innerHTML = '';
      this.resultsContainer.classList.remove('loading');
      return;
    }

    this.resultsContainer.innerHTML = `
        <svg class="loader" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="width: 300px; heght: 300px;"
            viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
          <path fill="#4d180d" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
              <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  dur="1s"
                  from="0 50 50"
                  to="360 50 50"
                  repeatCount="indefinite" />
          </path>
        </svg>
      `;

    this.resultsContainer.classList.add('loading');
  }

  bindListeners () {
    this.onQueryChange();
    this.onPageSizeChange();
  }

  onPageSizeChange () {
    const btns = this.section.querySelectorAll('.posts-per-page a');
    btns.forEach(el => {
      el.addEventListener('click', async (e) => {
        btns.forEach(el => el.classList.remove('active'));
        e.currentTarget.classList.add('active');

        this.page = 1;
        this.pageSize = e.currentTarget.dataset.pageSize;

        this.loading();
        await this.getData();
        this.renderResults();
        await this.updatePagination();
      });
    })
  }

  onQueryChange () {
    this.form.addEventListener('submit', async (e) => {
      e.preventDefault();

      const formData = new FormData(this.form);

      const val = formData.get('search');

      if (!val) {
        return;
      }

      this.currentQuery = val;
      this.page = 1;

      this.updateLocationSearch();
      this.loading();
      await this.getData();
      this.renderResults();
      await this.updatePagination();
    });
  }


  updateLocationSearch () {
    const url = new URL(window.location);
    const newVal = decodeURIComponent(this.currentQuery);

    url.searchParams.set('search', newVal);
    window.history.replaceState({}, '', url);
  }

  renderResults () {
    const searchItems = this.searchResult && this.searchResult.hits;
    const flatPhrases = this.flattenPhrasesResponse();
    const semanticItems = flatPhrases.length ? flatPhrases : '';
    const noData = (!searchItems || !searchItems.length) && (!semanticItems || !semanticItems.length);
    const noItemsTpl = `<article>${this.text['noItems']}</article>`;
    const categoryItems = this.categoryResult;

    this.loading(false);

    if (noData) {
      this.section.querySelector('.result-number').innerHTML = `0 ${this.text['results']}`;
      this.resultsContainer.innerHTML = noItemsTpl;
      return;
    }

    if (this.page === 1 && semanticItems) {
      this.appendItems(semanticItems, 'semantic');
    }

    let total = parseInt(this.searchResult.total, 10) + parseInt(this.semanticResult.total, 10);

    this.section.querySelector('.result-number').innerHTML = `${total} ${this.text['results']}`;
    this.appendItems(searchItems, 'search', categoryItems);
    this.showListAnimatedWay();
    this.onResultsClick();
  }

  flattenPhrasesResponse () {
    let result = [];

    if (!this.semanticResult || !this.semanticResult.hits) {
      return result;
    }

    const { hits } = this.semanticResult;

    result = hits.reduce((r, { highlight, phrase }) => {
      if (!phrase.links) {
        return r;
      }

      phrase.links.forEach(({ description: title, link }) => {
        r.push({
          ...(title && { title: title}),
          ...(highlight && { highlight: highlight}),
          ...(link && { link: link}),
          ...(phrase.jcrId && { jcrId: phrase.jcrId}),
          ...(phrase.phrase && { phrase: phrase.phrase}),
          ...(phrase.publicationDate && { publicationDate: phrase.publicationDate}),
          ...(phrase.siteName && { siteName: phrase.siteName}),
          type: 'semanticPhrase'
        });
      });

      return r;
    }, []);

    return result;
  }

  appendItems (source, type, category) {
    for (const el of source) {
      const tpl = type === 'search' ?
          this.searchResultItemTpl(el, category) :
          this.phraseItemTpl(el);

      this.resultsContainer.insertAdjacentHTML('beforeend', tpl);
    }
  }

  searchResultItemTpl (el, category) {
    const url = el.page.location;
    const highlight = el.highlight;
    const title = highlight.title && highlight.title.length ?
        highlight.title[0] :
        el.page.title ?
          el.page.title :
          '';
    const description = highlight.content && highlight.content.length ? highlight.content[0] : '';
    const date = el.page.publicationDate;
    const formattedDate = new Date(date).toLocaleDateString('pl-PL');
    const content = el.page.content.split(title).pop();
    let elementCategory = 'all';
    let categoryName = "WSZYSTKO";
    if (el.page.categories.length > 1) {
      elementCategory = el.page.categories[1];
    }
    for (const el of category.results) {
      if (el['@name'] === elementCategory) {
        categoryName = el.searchTabName;
        break;
      }
    }
    let trimmedContent = content.replace(/^(.{130}[^\s]*).*/, "$1");
    trimmedContent +='...';
    return `
    <div class="search-result">
        <div class="col-12 col-lg-2">
          <img class="search-result-img" src="${el.page.image}" alt="">
        </div>
        <div class="col-12 col-lg-10">
          <div class="search-result-content">
            <div class="search-result-content-wrapper">
              <span class="search-result-content-category">${categoryName}</span>
              <h4 class="search-result-content-title">${title}</h4>
              <p class="search-result-content-text">${trimmedContent}</p>
              <p>${formattedDate}</p>
            </div>
            <a href="${url}" class="btn-expand btn-expand-right btn-search-result">Sprawdź</a>
          </div>
        </div>
      </div>
    `;
  }

  phraseItemTpl (data) {
    const {
      link = '',
      title  = ''
    } = data;
    let tpl = '';
    const isoDate = data.publicationDate ? new Date(data.publicationDate).toISOString() : '';
    const formattedDate = data.publicationDate ? new Date(data.publicationDate).toLocaleDateString('pl-PL') : '';
    const dateHtml = data.publicationDate ? `<time datetime="${isoDate}">${formattedDate}</time>` : '';

    if (link && title) {
      tpl = `
        <article class="post-card single-search-result">
            ${dateHtml}
            <h3 class="entry-title">
                <a href="${link}">${title}</a>
            </h3>
        </article>
      `;
    }

    return tpl;
  }

  getBreadcrumbs (id, _url) {
    let idArr = id.split('/');
    let idArrLen = idArr.length;

    return idArr.map((el, i) => {
        let url = (i+1 === idArrLen) ? _url : '#';
        let name = i === 1 ? this.capitalize(el) : el;
        return el ?
          `<li>
              <a href="${url}" rel="bookmark">${name}</a>
          </li>` :
          '';
      }).join('')
  }

  capitalize (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  onResultsClick () {
    this.resultsContainer.querySelector('a').addEventListener('click', (e) => {
      const selectedPhrase = $(e.currentTarget).querySelector('.search-element-title').html();
      const selectedUrl = e.currentTarget.href;
      const searchPhrase = this.currentQuery;
      const cookieValue = this.getCookieValue([searchPhrase, selectedPhrase, selectedUrl, segment]);

      Cookies.setCookie('search-request', cookieValue, 0, true);
    });
  }

  getCookieValue (params = ['', '', '', '']) {
    return params.join('|');
  }

  showListAnimatedWay () {
    const items = this.resultsContainer.querySelectorAll('.search-result-item:not(.show)');

    for (let i = 0; i < items.length; i++) {
      setTimeout(function () {
        items[i].classList.add('show');
      }, 500 + 100 * (i + 1));
    }
  }

}
