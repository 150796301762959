import debounce from '../helpers/debounce';

class Header {
  constructor() {
    this.init();
  }

  init() {
    this.getDomEle()
      .then(this.attachEvent.bind(this));
  }

  getDomEle() {
    return new Promise((resolve) => {
      this.body = document.querySelector('body');
      this.headerElement = document.querySelector('.main-header');
      this.hamburgerBtn = document.querySelector('.header-hamburger');
      this.hamburgerMenu = document.querySelector('.header-items');
      this.headerSwitch = this.headerElement.offsetHeight;

      resolve();
    });
  }

  attachEvent() {
    return new Promise((resolve) => {
      const scrollHeader = debounce(() => {
        const top = document.documentElement.scrollTop;

        if (top > this.headerSwitch) {
          this.headerElement.classList.add('sticky');
          this.body.classList.add('nav-sticky');
        } else {
          this.headerElement.classList.remove('sticky');
          this.body.classList.remove('nav-sticky');
        }
      }, 100);

      window.addEventListener('scroll', scrollHeader);

      this.hamburgerBtn.addEventListener('click', () => {
        this.hamburgerMenu.classList.toggle('hamburger-open');
        this.hamburgerBtn.classList.toggle('hamburger');
        this.hamburgerBtn.classList.toggle('close');
      });

      resolve();
    });
  }
}

export default Header;
