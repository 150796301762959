import Header from "./components/header";
import Accordion from "./components/accordion";
import SearchResults from "./components/search-results";

class App {

  constructor() {
    new Header();
    if (document.querySelector('.wysiwyg-accordion')) new Accordion();   
    
    const search = document.querySelector('.search-results');
    if (search) {
      const searchResults = new SearchResults(search);
      searchResults.init();
    }
  }

}



document.addEventListener("DOMContentLoaded", () => new App());
