class Accordion {
  constructor() {
    this.init();
  }

  init() {
    this.getDomEle();
    this.attachEvent();
  }

  getDomEle() {
    this.accordionBtn = document.querySelectorAll('.btn-expand-right-center');
    this.panel = document.querySelectorAll('.wysiwyg-accordion');
  }

  attachEvent() {
    this.accordionBtn.forEach((el, index) => {
      el.addEventListener('click', () => {
        el.classList.toggle('active');
        el.classList.toggle('collapse');
        if (this.panel[index].style.maxHeight) {
          el.textContent = 'Rozwiń';
          this.panel[index].style.maxHeight = null;
        } else {
          this.panel[index].style.maxHeight = `${this.panel[index].scrollHeight}px`;
          el.textContent = 'Zwiń';
        }
      });
    });
  }
}

export default Accordion;
